var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c(
                "form",
                {
                  staticClass: "needs-validation",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.formSubmit($event)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-6" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "deptName" } }, [
                          _vm._v("Numéro de chambre")
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.roomForm.roomNumber,
                              expression: "roomForm.roomNumber"
                            }
                          ],
                          staticClass: "form-control",
                          class: {
                            "is-invalid":
                              _vm.submitted && _vm.$v.roomForm.roomNumber.$error
                          },
                          attrs: {
                            id: "deptName",
                            type: "text",
                            placeholder: "",
                            value: ""
                          },
                          domProps: { value: _vm.roomForm.roomNumber },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.roomForm,
                                "roomNumber",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm.submitted && _vm.$v.roomForm.roomNumber.$error
                          ? _c("div", { staticClass: "invalid-feedback" }, [
                              !_vm.$v.roomForm.roomNumber.required
                                ? _c("span", [
                                    _vm._v(
                                      "Le numéro de chambre est obligatoire."
                                    )
                                  ])
                                : _vm._e(),
                              !_vm.$v.roomForm.roomNumber.maxLength
                                ? _c("span", [
                                    _vm._v(
                                      "La taille du champs est limitée a 64 charactères."
                                    )
                                  ])
                                : _vm._e()
                            ])
                          : _vm._e()
                      ])
                    ]),
                    _c("div", { staticClass: "col-md-6" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "validationCustom02" } }, [
                          _vm._v("Bloc")
                        ]),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.roomForm.roomBloc,
                                expression: "roomForm.roomBloc"
                              }
                            ],
                            staticClass: "custom-select",
                            class: {
                              "is-invalid":
                                _vm.submitted && _vm.$v.roomForm.roomBloc.$error
                            },
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.roomForm,
                                  "roomBloc",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              }
                            }
                          },
                          [
                            _c("option", { attrs: { selected: "" } }, [
                              _vm._v("Sélectionner une classe")
                            ]),
                            _vm._l(_vm.blocs, function(bloc) {
                              return _c(
                                "option",
                                { key: bloc.id, domProps: { value: bloc.id } },
                                [_vm._v(" " + _vm._s(bloc.name) + " ")]
                              )
                            })
                          ],
                          2
                        ),
                        _vm.submitted && _vm.$v.roomForm.roomBloc.$error
                          ? _c("div", { staticClass: "invalid-feedback" }, [
                              !_vm.$v.roomForm.roomBloc.required
                                ? _c("span", [
                                    _vm._v("Le bloc est obligatoire.")
                                  ])
                                : _vm._e()
                            ])
                          : _vm._e()
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-6" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "validationCustom02" } }, [
                          _vm._v("Type")
                        ]),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.roomForm.roomType,
                                expression: "roomForm.roomType"
                              }
                            ],
                            staticClass: "custom-select",
                            class: {
                              "is-invalid":
                                _vm.submitted && _vm.$v.roomForm.roomType.$error
                            },
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.roomForm,
                                  "roomType",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              }
                            }
                          },
                          [
                            _c("option", { attrs: { selected: "" } }),
                            _vm._l(_vm.roomTypes, function(type) {
                              return _c(
                                "option",
                                { key: type.id, domProps: { value: type.id } },
                                [_vm._v(" " + _vm._s(type.name) + " ")]
                              )
                            })
                          ],
                          2
                        ),
                        _vm.submitted && _vm.$v.roomForm.roomType.$error
                          ? _c("div", { staticClass: "invalid-feedback" }, [
                              !_vm.$v.roomForm.roomType.required
                                ? _c("span", [
                                    _vm._v("Le type est obligatoire.")
                                  ])
                                : _vm._e()
                            ])
                          : _vm._e()
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-12" }, [
                      _c("h3", [_vm._v("Fiche d'inventaire:")]),
                      _c("hr"),
                      _c(
                        "div",
                        [
                          _vm._l(_vm.roomForm.equipments, function(
                            equipment,
                            index
                          ) {
                            return _c(
                              "div",
                              { key: equipment.id, staticClass: "row" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "form-group col-lg-3" },
                                  [
                                    _c("label", { attrs: { for: "message" } }, [
                                      _vm._v("Equipement")
                                    ]),
                                    _c("multiselect", {
                                      attrs: {
                                        searchable: true,
                                        "track-by": "id",
                                        label: "name",
                                        options: _vm.equipments,
                                        placeholder: "Selectionner",
                                        "allow-empty": false
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "singleMealType",
                                            fn: function(ref) {
                                              var type = ref.type
                                              return [_vm._v(_vm._s(type.name))]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      ),
                                      model: {
                                        value: equipment.equipment,
                                        callback: function($$v) {
                                          _vm.$set(equipment, "equipment", $$v)
                                        },
                                        expression: "equipment.equipment"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "form-group col-lg-3" },
                                  [
                                    _c("label", { attrs: { for: "resume" } }, [
                                      _vm._v("N° Série")
                                    ]),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: equipment.serial_no,
                                          expression: "equipment.serial_no"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: { id: "subject", type: "text" },
                                      domProps: { value: equipment.serial_no },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            equipment,
                                            "serial_no",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "form-group col-lg-2" },
                                  [
                                    _c("label", { attrs: { for: "subject" } }, [
                                      _vm._v("N° Inventaire")
                                    ]),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: equipment.inventory_no,
                                          expression: "equipment.inventory_no"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: { id: "subject", type: "text" },
                                      domProps: {
                                        value: equipment.inventory_no
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            equipment,
                                            "inventory_no",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "form-group col-lg-3" },
                                  [
                                    _c("label", { attrs: { for: "message" } }, [
                                      _vm._v("Status")
                                    ]),
                                    _c("multiselect", {
                                      attrs: {
                                        searchable: true,
                                        "track-by": "id",
                                        label: "name",
                                        options: _vm.equipmentStatus,
                                        placeholder: "Selectionner",
                                        "allow-empty": false
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "singleMealType",
                                            fn: function(ref) {
                                              var type = ref.type
                                              return [_vm._v(_vm._s(type.name))]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      ),
                                      model: {
                                        value: equipment.status,
                                        callback: function($$v) {
                                          _vm.$set(equipment, "status", $$v)
                                        },
                                        expression: "equipment.status"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-lg-1 align-self-center" },
                                  [
                                    _vm.roomForm.equipments.length > 1 &&
                                    index != 0
                                      ? _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: "javascript:void(0)"
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "bx bx-trash-alt",
                                              on: {
                                                click: function($event) {
                                                  return _vm.deleteRow(index)
                                                }
                                              }
                                            })
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ]
                            )
                          }),
                          _c("input", {
                            staticClass: "btn btn-success mt-3 mt-lg-0",
                            attrs: { type: "button", value: "Ajouter" },
                            on: { click: _vm.AddformData }
                          })
                        ],
                        2
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-12" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "formrow-inputCity" } }, [
                          _vm._v("Commentaires")
                        ]),
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.roomForm.notes,
                              expression: "roomForm.notes"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { cols: "30", rows: "4" },
                          domProps: { value: _vm.roomForm.notes },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.roomForm,
                                "notes",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ])
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "submit" }
                    },
                    [_vm._v("Enregistrer")]
                  )
                ]
              )
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }